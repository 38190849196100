import React, { Suspense } from "react";
import { CircularProgress, Box } from "@mui/material"; // Using MUI for a loading spinner

// A higher-order component that wraps around React.lazy loaded components
const Loadable = (Component) => (props) =>
  (
    <Suspense
      fallback={
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      }
    >
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
