import React, { useState } from "react";
import { Link } from "react-router-dom";
export default function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <nav className="navBar">
      <Link to="/home" className="siteTitle">
        Prediction Game
      </Link>
      <div id="desktopNav">
        <ul>
          <li>
            <Link to="questions">Questions</Link>
          </li>
          <li>
            <Link to="upcoming">Upcoming</Link>
          </li>
          <li>
            <Link to="connect">Connect</Link>
          </li>
          <li>
            <Link to="about">About</Link>
          </li>
        </ul>
      </div>
      <div id="mobileNav" className={isMobileMenuOpen ? "open" : ""}>
        <i className="fas fa-bars" onClick={toggleMobileMenu}></i>
        <ul className="mobileMenu">
          <li onClick={toggleMobileMenu}>
            <Link to="questions">Questions</Link>
          </li>
          <li onClick={toggleMobileMenu}>
            <Link to="upcoming">Upcoming</Link>
          </li>
          <li onClick={toggleMobileMenu}>
            <Link to="connect">Connect</Link>
          </li>
          <li onClick={toggleMobileMenu}>
            <Link to="about">About</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
