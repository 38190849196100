import { lazy } from "react";
import Loadable from "../components/Loadable";
import Home from "../pages/Auth/Home";

const Connect = Loadable(lazy(() => import("../pages/Auth/Connect")));
const About = Loadable(lazy(() => import("../pages/Public/About")));
const Privacy = Loadable(lazy(() => import("../pages/Public/Privacy")));
const Terms = Loadable(lazy(() => import("../pages/Public/Terms")));
const Questions = Loadable(lazy(() => import("../pages/Auth/Questions")));
const Upcoming = Loadable(lazy(() => import("../pages/Auth/Upcoming")));

const PublicRoutes = {
  path: "/",
  element: <Home />,
  children: [
    { path: "home", element: <Home /> },
    { path: "upcoming", element: <Upcoming /> },
    { path: "questions", element: <Questions /> },
    { path: "about", element: <About /> },
    { path: "connect", element: <Connect /> },
    { path: "privacy", element: <Privacy /> },
    { path: "terms", element: <Terms /> },
  ],
};

export default PublicRoutes;
