import React from "react";
import Navbar from "./components/Navbar"; // Ensure this path is correct
import PgRoutes from "./routes/PgRoutes"; // Ensure this path is correct
import "./App.css";

const App = () => {
  return (
    <>
      <Navbar />
      {/* Render the routes below the Navbar */}
      <PgRoutes />
    </>
  );
};

export default App;
